import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { firebase, db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { Button, Form, Input, Modal, Icon, notification, Radio, Select } from 'antd'
import PersonAvatar from '../../customcomponents/PersonAvatar'
const FormItem = Form.Item
const { TextArea } = Input

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}

class PrintableTestAddGrade extends Component {
    state = {
        teacherId: '',
        teacher: [],
        student: null,
        submitting: false,
        visible: false,
    }

    componentDidMount() {

    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    handleOk = (e) => {
        // console.log(e)
        this.setState({
            visible: false,
        })
    }

    handleCancel = (e) => {
        //  console.log(e)
        this.setState({
            visible: false,
        })
    }


    // add a new event to the teacher's events
    handleSubmit = (e) => {
        e.preventDefault()
        if (!this.props.assessment) {
            return;
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    submitting: true,
                }, () => {
                    //console.log('Received values of form: ', values)
                    let manualGrades = {}
                    if (this.props.assessment.hasOwnProperty('manualGrades')) {
                        manualGrades = this.props.assessment.manualGrades
                    }

                    if (manualGrades.hasOwnProperty(values.studentId)) {
                        delete manualGrades[values.studentId]
                    }

                    db.collection(ColType.cleverAssessments)
                        .doc(this.props.assessment.id)
                        .update({
                            manualGrades: manualGrades,
                        }).then(() => {
                            this.setState({
                                submitting: false,
                            })
                            successMessage('Grade removed.')
                            this.hideModal()
                            this.props.form.resetFields()
                        }).catch((error) => {
                            this.setState({
                                submitting: false,
                            })
                            errorMessage('An error occurred.');
                        })

                })
            }
        })
    }

    getSortedStudents = () => {
        if (!this.props.assessment) {
            return [];
        }
        if (!(this.props.teacherBaseProps &&
            this.props.teacherBaseProps.studentDict)) {
            return [];
        }
        let students = [];
        Object.keys(this.props.assessment.studentGrades).map((studentId) => {
            if (this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId) &&
                (this.props.assessment.hasOwnProperty('manualGrades') &&
                    this.props.assessment.manualGrades &&
                    this.props.assessment.manualGrades.hasOwnProperty(studentId))) {
                students.push(this.props.teacherBaseProps.studentDict[studentId]);
            }
            return false;
        });

        students.sort((a, b) => {
            const aGrade = a.grade
            const bGrade = b.grade
            if (aGrade === bGrade) {
                return a.lastName.localeCompare(b.lastName);
            }
            return aGrade - bGrade;
        });

        return students;
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        const assessment = this.props.assessment
        if (!assessment) {
            return ''
        }

        //console.log('assessment', assessment)

        return (
            <div>
                <Button
                    onClick={this.showModal}
                    size={'large'}
                    type='primary'
                    className='lg-btn'
                >
                    <Icon type="delete" />
                    Remove grade
                </Button>
                <Modal
                    title="Remove grade"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <div className='mb-2 font-24'>Remove grade for a printed test:</div>
                    <Form onSubmit={this.handleSubmit}
                        className="block-label-form form-label-font-normal">
                        <FormItem {...formItemLayout} label="Students with printed test grades">
                            {getFieldDecorator('studentId', {
                                rules: [{ required: true, message: 'Required.' }],
                            })(
                                <Select size='large' placeholder="Select student">
                                    {this.getSortedStudents().map((student) => {
                                        const studentId = student.id;
                                        return <Select.Option key={studentId}
                                            value={studentId}>
                                            <PersonAvatar
                                                person={student}
                                                size={'large'}
                                                containerClassName="font-20"
                                                avatarClassName="font-20 font-bold mr-2"
                                                personClassName="font-black font-20 ellipsis"
                                            />
                                        </Select.Option>;
                                    })}
                                </Select>
                            )}
                        </FormItem>



                        {/*<div className='mt-3 mb-4 font-24 flex w-100'>
                            <div className='ml-auto font-black'>
                                Final grade: {(this.getFinalGrade(this.props.form.getFieldsValue()) * 100).toFixed(2)}%
                            </div>
                        </div>*/}

                        <div className="flex flex-v-center">
                            <Button
                                size={'large'}
                                disabled={this.state.submitting}
                                onClick={this.hideModal}
                                className="ml-auto mr-1 lg-btn"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.handleSubmit}
                                disabled={this.state.submitting}
                                className='lg-btn'
                            >
                                Remove grade
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </div>

        )
    }
}

export default Form.create()(PrintableTestAddGrade)

import React, { Component } from 'react'
import '../../styles/GlobalStyle.css'
import { firebase, db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { Button, Form, Input, Modal, Icon, notification, Radio, Select } from 'antd'
import PersonAvatar from '../../customcomponents/PersonAvatar'
const FormItem = Form.Item
const { TextArea } = Input

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    })
}

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    })
}
class PrintableTestAddGrade extends Component {
    state = {
        teacherId: '',
        teacher: [],
        student: null,
        submitting: false,
        visible: false,
    }

    componentDidMount() {

    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    handleOk = (e) => {
        // console.log(e)
        this.setState({
            visible: false,
        })
    }

    handleCancel = (e) => {
        //  console.log(e)
        this.setState({
            visible: false,
        })
    }


    // add a new event to the teacher's events
    handleSubmit = (e) => {
        e.preventDefault()
        if (!this.props.assessment) {
            return;
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    submitting: true,
                }, () => {
                    //console.log('Received values of form: ', values)
                    const finalGrade = this.getFinalGrade(values)

                    let gradeObj = {
                        chosenAnswers: {},
                        chosenAnswersBaseQuiz: {},
                        finalGrade: {
                            correct: 0,
                            total: this.props.assessment.quiz.docs.length,
                            grade: finalGrade,
                        },
                        graded: true,
                        manualGrade: true,
                    }

                    try {
                        let allQuestionsFound = true
                        this.props.assessment.quiz.docs.map((lessonPlanItem, idx) => {
                            const questionIdx = 'question#' + idx;
                            if (values.hasOwnProperty(questionIdx)) {
                                const answerIdx = values[questionIdx]
                                const answerDocId = lessonPlanItem.components[0].answers[answerIdx].id
                                const correct = lessonPlanItem.components[0].answers[answerIdx].correct
                                if (correct) {
                                    gradeObj.finalGrade.correct++
                                }
                                gradeObj.chosenAnswers[idx] = answerIdx
                                gradeObj.chosenAnswersBaseQuiz[lessonPlanItem.metadata.uuid] = answerDocId
                            } else {
                                allQuestionsFound = false
                            }
                        });

                        if (!allQuestionsFound) {
                            this.setState({
                                submitting: false,
                            })
                            errorMessage('An error occurred.')
                            return;
                        }

                        let manualGrades = {}
                        if (this.props.assessment.hasOwnProperty('manualGrades')) {
                            manualGrades = this.props.assessment.manualGrades
                        }

                        manualGrades[values.studentId] = gradeObj

                        db.collection(ColType.cleverAssessments)
                            .doc(this.props.assessment.id)
                            .update({
                                manualGrades: manualGrades,
                            }).then(() => {
                                this.setState({
                                    submitting: false,
                                })
                                successMessage('Grade added.')
                                this.hideModal()
                                this.props.form.resetFields()
                            }).catch((error) => {
                                this.setState({
                                    submitting: false,
                                })
                                errorMessage('An error occurred.');
                            })
                    } catch (e) {
                        this.setState({
                            submitting: false,
                        })
                        errorMessage('An error occurred.')
                        return;
                    }
                })
            }
        })
    }

    getFinalGrade = (values) => {
        if (!this.props.assessment) {
            return 0;
        }
        if (!values) {
            return 0;
        }

        let total = this.props.assessment.quiz.docs.length;
        let correct = 0;

        Object.keys(values).map((key) => {
            if (values[key] === undefined || values[key] === null) {
                return false;
            }
            if (key.indexOf('question#') === -1) {
                return false;
            }
            let answerIdx = values[key];
            let questionIdx = parseInt(key.split('#')[1], 10);
            let questionDoc = this.props.assessment.quiz.docs[questionIdx];
            if (questionDoc.components[0].answers[answerIdx].correct) {
                correct++;
            }
            return false;
        });

        if (total === 0) {
            return 0;
        }

        return correct / total;
    }

    getSortedStudents = () => {
        if (!this.props.assessment) {
            return [];
        }
        if (!(this.props.teacherBaseProps &&
            this.props.teacherBaseProps.studentDict)) {
            return [];
        }
        let students = [];
        Object.keys(this.props.assessment.studentGrades).map((studentId) => {
            if (this.props.teacherBaseProps.studentDict.hasOwnProperty(studentId)) {
                students.push(this.props.teacherBaseProps.studentDict[studentId]);
            }
            return false;
        });

        students.sort((a, b) => {
            const aGrade = a.grade
            const bGrade = b.grade
            if (aGrade === bGrade) {
                return a.lastName.localeCompare(b.lastName);
            }
            return aGrade - bGrade;
        });

        return students;
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        }

        const assessment = this.props.assessment
        if (!assessment) {
            return ''
        }

        //console.log('assessment', assessment)

        return (
            <div>
                <Button
                    onClick={this.showModal}
                    size={'large'}
                    type='primary'
                    className='lg-btn mr-1'
                >
                    <Icon type="plus" />
                    Add grade
                </Button>
                <Modal
                    title="Add grade"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <div className='mb-2 font-24'>Add grade for a completed printed test:</div>
                    <Form onSubmit={this.handleSubmit}
                        className="block-label-form form-label-font-normal">
                        <FormItem {...formItemLayout} label="Student">
                            {getFieldDecorator('studentId', {
                                rules: [{ required: true, message: 'Required.' }],
                            })(
                                <Select size='large' placeholder="Select student">
                                    {this.getSortedStudents().map((student) => {
                                        const studentId = student.id;
                                        return <Select.Option key={studentId}
                                            value={studentId}>
                                            <PersonAvatar
                                                person={student}
                                                size={'large'}
                                                containerClassName="font-20"
                                                avatarClassName="font-20 font-bold mr-2"
                                                personClassName="font-black font-20 ellipsis"
                                            />
                                        </Select.Option>;
                                    })}
                                </Select>
                            )}
                        </FormItem>

                        <div className='font-20 font-black mb-1'>Select what the student chose for each question:</div>
                        {assessment.quiz.docs.map((lessonPlanItem, idx) => {
                            return <FormItem {...formItemLayout} label={"Question #" + (idx + 1)}>
                                {getFieldDecorator('question#' + idx, {
                                    rules: [{ required: true, message: 'Required.' }],
                                })(
                                    <Radio.Group size='large'>
                                        <Radio value={0}><span style={{ verticalAlign: '-4px' }}>A</span></Radio>
                                        <Radio value={1}><span style={{ verticalAlign: '-4px' }}>B</span></Radio>
                                        <Radio value={2}><span style={{ verticalAlign: '-4px' }}>C</span></Radio>
                                        <Radio value={3}><span style={{ verticalAlign: '-4px' }}>D</span></Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>
                        })}

                        <div className='mt-3 mb-4 font-24 flex w-100'>
                            <div className='ml-auto font-black'>
                                Final grade: {(this.getFinalGrade(this.props.form.getFieldsValue()) * 100).toFixed(2)}%
                            </div>
                        </div>

                        <div className="flex flex-v-center">
                            <Button
                                size={'large'}
                                disabled={this.state.submitting}
                                onClick={this.hideModal}
                                className="ml-auto mr-1 lg-btn"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                size={'large'}
                                onClick={this.handleSubmit}
                                disabled={this.state.submitting}
                                className='lg-btn'
                            >
                                Add grade
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </div>

        )
    }
}

export default Form.create()(PrintableTestAddGrade)

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getIDFromURL } from '../.././Util'
import { Button, Checkbox, Icon, Layout, Popconfirm, Select, Switch, Tooltip, notification, Modal, Input, Radio } from 'antd'
import { db } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { capitalizeFirstChar, flattenDoc, getAssessmentDisplayName, getFullAssessmentDisplayName } from '../../Util'
import PersonAvatar from '../../customcomponents/PersonAvatar'
import moment from 'moment'
const { Content } = Layout
//var moment = require('moment')

class PacingGuideAssessments extends Component {
    state = {
        sectionsFilterValue: 'all',
        assessmentIsOpenChangeDict: {},
        deleteModalVisible: false,
        deleteInputValue: '',
        selectedDeleteAssessment: null,
        settingAssessmentStatus: false,
        assessmentMode: 'current',
        initialScrollDone: false,
    }

    componentDidMount() {
        //window.scrollTo(0, 0)
        this.doInitialScroll(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.doInitialScroll(nextProps)
    }

    doInitialScroll = (props) => {
        if (props.teacherBaseProps &&
            props.teacherBaseProps.students &&
            props.assessmentBaseProps &&
            props.assessmentBaseProps.dataRetrieved &&
            !this.state.initialScrollDone) {
            this.setState({
                initialScrollDone: true
            }, () => {
                setTimeout(() => {
                    // scroll to the right if not previously scrolled
                    if (document.documentElement.scrollLeft === 0) {
                        document.documentElement.scrollLeft = document.documentElement.scrollWidth - document.documentElement.clientWidth;
                    }
                }, 1);
            });
        }
    }

    showDeleteModal = () => {
        this.setState({
            deleteModalVisible: true,
        });
    }

    hideDeleteModal = () => {
        this.setState({
            deleteModalVisible: false,
            selectedDeleteAssessment: null,
            deleteInputValue: '',
        });
    }

    onSelectSectionsFilterChange = (value) => {
        this.setState({
            sectionsFilterValue: value
        })
    }

    onDeleteInputValueChange = (e) => {
        this.setState({
            deleteInputValue: e.target.value
        })
    }

    onAssessmentOpenChange = (assessment, isOpen) => {
        //console.log('onAssessmentOpenChange', assessment, isOpen)
        if (!assessment) {
            return
        }

        let assessmentIsOpenChangeDict = this.state.assessmentIsOpenChangeDict
        assessmentIsOpenChangeDict[assessment.id] = isOpen
        this.setState({
            assessmentIsOpenChangeDict
        })

        let quizType = 'quiz'
        if (assessment.quiz.assessmentCategory === "common_assessment") {
            quizType = 'common assessment'
        } else if (assessment.quiz.assessmentCategory === "benchmark_assessment") {
            quizType = 'benchmark'
        }

        db.collection(ColType.cleverStudentAssessments)
            .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
            .where('parentQuizId', '==', assessment.id)
            .get()
            .then(async (querySnapshot) => {
                let studentAssessments = []
                querySnapshot.forEach((doc) => {
                    let studentAssessment = flattenDoc(doc)
                    studentAssessments.push(studentAssessment)
                })
                //console.log('studentAssessments', studentAssessments)

                try {
                    const allStudentAssessments = studentAssessments;
                    const batchSize = 500;

                    for (let i = 0; i < allStudentAssessments.length; i += batchSize) {
                        const batch = db.batch();

                        for (let j = i; j < i + batchSize; j++) {
                            if (j >= allStudentAssessments.length) {
                                break;
                            }
                            const studentAssessment = allStudentAssessments[j];

                            const docRef = db.collection(ColType.cleverStudentAssessments).doc(studentAssessment.id);
                            batch.update(docRef, { isOpen });
                        }

                        await batch.commit();
                    }

                    await db.collection(ColType.cleverAssessments).doc(assessment.id).update({ isOpen });

                    let assessmentIsOpenChangeDict = this.state.assessmentIsOpenChangeDict
                    delete assessmentIsOpenChangeDict[assessment.id]
                    this.setState({
                        assessmentIsOpenChangeDict
                    })
                    notification.open({
                        type: 'success',
                        duration: 5,
                        message: `Testing for the ${quizType} is now ${isOpen ? 'open' : 'closed'}.`,
                        placement: 'bottomRight'
                    });
                } catch (error) {
                    console.error('An error occurred:', error);
                    let assessmentIsOpenChangeDict = this.state.assessmentIsOpenChangeDict
                    delete assessmentIsOpenChangeDict[assessment.id]
                    this.setState({
                        assessmentIsOpenChangeDict
                    })
                    notification.open({
                        type: 'error',
                        duration: 5,
                        message: 'An error occurred, please try again.',
                        placement: 'bottomRight'
                    });
                }
            })
    }

    deleteAssessmentShowModal = (assessment) => {
        this.setState({
            deleteModalVisible: true,
            selectedDeleteAssessment: assessment,
        })
    }

    setAssessmentStatus = (assessment, status) => {
        if (!assessment) {
            //console.log('no assessment, returning')
            return
        }
        if (status === 'deleted' && this.state.deleteInputValue !== 'delete') {
            //console.log('delete input value is not delete, returning')
            return
        }

        this.setState({
            settingAssessmentStatus: true,
        })

        let quizType = 'quiz'
        if (assessment.quiz.assessmentCategory === "common_assessment") {
            quizType = 'common assessment'
        } else if (assessment.quiz.assessmentCategory === "benchmark_assessment") {
            quizType = 'benchmark'
        }

        db.collection(ColType.cleverStudentAssessments)
            .where('teacherId', '==', this.props.teacherBaseProps.teacher.id)
            .where('parentQuizId', '==', assessment.id)
            .get()
            .then(async (querySnapshot) => {
                let studentAssessments = []
                querySnapshot.forEach((doc) => {
                    let studentAssessment = flattenDoc(doc)
                    studentAssessments.push(studentAssessment)
                })
                //console.log('studentAssessments', studentAssessments)

                try {
                    const allStudentAssessments = studentAssessments;
                    const batchSize = 500;

                    for (let i = 0; i < allStudentAssessments.length; i += batchSize) {
                        const batch = db.batch();

                        for (let j = i; j < i + batchSize; j++) {
                            if (j >= allStudentAssessments.length) {
                                break;
                            }
                            const studentAssessment = allStudentAssessments[j];

                            const docRef = db.collection(ColType.cleverStudentAssessments).doc(studentAssessment.id);
                            batch.update(docRef, {
                                status: status,
                            });
                        }

                        await batch.commit();
                    }

                    await db.collection(ColType.cleverAssessments).doc(assessment.id).update({
                        status: status,
                    });

                    this.setState({
                        settingAssessmentStatus: false,
                        selectedDeleteAssessment: null,
                        deleteInputValue: '',
                        deleteModalVisible: false,
                    })

                    let message = 'Assessment deleted successfully.'
                    if (status === 'current') {
                        message = 'Assessment restored successfully.'
                    }

                    notification.open({
                        type: 'success',
                        duration: 5,
                        message: message,
                        placement: 'bottomRight'
                    });
                } catch (error) {
                    console.error('An error occurred:', error);
                    this.setState({
                        settingAssessmentStatus: false,
                    })
                    notification.open({
                        type: 'error',
                        duration: 5,
                        message: 'An error occurred, please try again.',
                        placement: 'bottomRight'
                    });
                }
            })
    }

    getAsessmentsForMode = () => {
        if (this.state.assessmentMode === 'current' &&
            this.props.assessmentBaseProps &&
            this.props.assessmentBaseProps.assessments) {
            return this.props.assessmentBaseProps.assessments
        } else if (this.state.assessmentMode === 'deleted' &&
            this.props.assessmentBaseProps &&
            this.props.assessmentBaseProps.deletedAssessments) {
            return this.props.assessmentBaseProps.deletedAssessments
        }
        return []
    }

    render() {
        return (
            <div className='w-100'
                style={{ minWidth: '100%' }} >
                <Layout className="content layout-header-mt flex w-100"
                >
                    <Layout className="mt-0 flex w-100">
                        <Content className="mt-0 block w-100 relative">
                            {/*
                            <div className='w-100' style={{
                                paddingLeft: '20px', paddingRight: '20px',
                                position: 'fixed', top: '117px',
                                left: '0px'
                            }}>
                                <div className='relative mb-2'>
                                    <img src="/home-banner2.png" alt="home" className='w-100 br-20'
                                        style={{ objectFit: 'cover', maxHeight: '188px' }} />
                                    <div className='absolute-tl w-100 h-100 p-4 flex flex-v-center'>
                                        <div>
                                            <div>
                                                <div className='font-40 font-bold font-fff'>Assessments</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}
                            <div className='background-fff relative' style={{ paddingRight: '20px' }}>

                                {this.props.assessmentBaseProps &&
                                    this.props.assessmentBaseProps.assessments ?
                                    <div

                                    >
                                        <table
                                            className='assessment-table font-20 w-100 background-fff h-100'
                                            style={{ overflowX: 'auto', maxWidth: '100%' }}>
                                            <thead className='background-fff'>

                                                <tr className='background-fff ant-shadow-light' style={{ position: 'sticky', top: '85px', zIndex: '2' }}>
                                                    <th style={{
                                                        position: 'sticky', left: '0px', zIndex: '1', verticalAlign: 'top',
                                                        background: '#e4e4e4'
                                                    }}>
                                                        <div>
                                                            <div className='font-24 font-bold mb-2'>My Assessments</div>
                                                            <div className='flex flex-v-center'>
                                                                <Icon type='filter' className='mr-1' />
                                                                <Select value={this.state.sectionsFilterValue}
                                                                    style={{ minWidth: '250px', width: '250px' }}
                                                                    onChange={this.onSelectSectionsFilterChange}>
                                                                    <Select.Option key='section-all' value='all'>All Sections</Select.Option>
                                                                    {this.props.teacherBaseProps &&
                                                                        this.props.teacherBaseProps.sections &&
                                                                        this.props.teacherBaseProps.sections.map((section, index) => {
                                                                            return <Select.Option key={'section-' + section.id} value={section.id}>{section.name}</Select.Option>
                                                                        })}
                                                                </Select>
                                                            </div>
                                                            <div className='mt-1'>
                                                                {!(this.props.assessmentBaseProps && !this.props.assessmentBaseProps.dataRetrieved) ?
                                                                    <div>

                                                                        <Checkbox checked={this.state.assessmentMode === 'deleted'}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    assessmentMode: e.target.checked ? 'deleted' : 'current'
                                                                                })
                                                                            }}><span className='pl-1'>Show deleted assessments</span></Checkbox>

                                                                    </div> : ''}
                                                            </div>
                                                        </div>
                                                    </th>
                                                    {this.props.assessmentBaseProps && !this.props.assessmentBaseProps.dataRetrieved ?
                                                        <th>
                                                            <div className='font-16'>
                                                                <div>Loading Assessments...</div>
                                                                <Icon type='loading' />
                                                            </div>
                                                        </th>
                                                        : ''}
                                                    {this.getAsessmentsForMode().map((assessment, index) => {
                                                        if (!assessment.hasOwnProperty('studentGrades')) {
                                                            return false
                                                        }
                                                        if (this.state.sectionsFilterValue !== 'all' &&
                                                            assessment.hasOwnProperty('sectionIds') &&
                                                            assessment.sectionIds &&
                                                            !assessment.sectionIds.includes(this.state.sectionsFilterValue)) {
                                                            return false
                                                        }
                                                        let assessmentDisplayName = getFullAssessmentDisplayName(assessment)
                                                        let testingIsOpen = true
                                                        // default to close testing if a week has passed since the assessment was created
                                                        /*if (assessment.hasOwnProperty('timeStamp') &&
                                                            assessment.timeStamp &&
                                                            assessment.timeStamp.hasOwnProperty('seconds')) {
                                                            let assessmentMoment = moment.utc(assessment.timeStamp.seconds * 1000)
                                                            let assessmentMomentEnd = assessmentMoment.clone().add(1, 'week')
                                                            if (this.props.teacherBaseProps.currentTimeStamp) {
                                                                if (this.props.teacherBaseProps.currentTimeStamp.isAfter(assessmentMomentEnd)) {
                                                                    testingIsOpen = false
                                                                }
                                                            }
                                                        }*/
                                                        // but an existing isOpen property overrides that default
                                                        if (assessment.hasOwnProperty('isOpen')) {
                                                            testingIsOpen = assessment.isOpen
                                                        }
                                                        return <th key={'th-' + assessment.id}
                                                            style={{ verticalAlign: 'top' }}>
                                                            <div className='h-100 flex' style={{ flexDirection: 'column' }}>
                                                                <Link to={"/teacher/quizzes/quiz/" + this.props.teacherBaseProps.teacher.id + "?assessment=" + assessment.id} key={assessment.id}>
                                                                    <div>
                                                                        <div className='font-14'>
                                                                            {assessmentDisplayName}
                                                                        </div>
                                                                        {/*
                                                                        <Popconfirm placement='bottom' title='Are you sure you want to delete this quiz?'
                                                                            onConfirm={(e) => { }}>
                                                                            <Button type='danger' size='large' className='br-50' style={{ width: '32px', height: '32px' }}>
                                                                                <Icon type='delete' />
                                                                            </Button>
                                                                        </Popconfirm>
                                                                        */}
                                                                    </div>
                                                                </Link>
                                                                <div className='font-normal font-14 text-muted-lighter'>
                                                                    {assessment.hasOwnProperty('timeStamp') &&
                                                                        assessment.timeStamp &&
                                                                        assessment.timeStamp.hasOwnProperty('seconds') ?
                                                                        moment.utc(assessment.timeStamp.seconds * 1000).format("MM/DD/YYYY") :
                                                                        ''}
                                                                </div>
                                                                <div style={{ marginTop: 'auto' }}>
                                                                    <div className='flex flex-v-center font-normal'
                                                                    >
                                                                        <Tooltip mouseEnterDelay={.5} overlayStyle={{ minWidth: '250px' }} title={<div className='font-14'>{testingIsOpen ? 'Testing is open. Students can take the assessment. Click to close testing.' :
                                                                            'Testing is closed. Students cannot take the assessment. Click to open testing.'}</div>}>
                                                                            {this.state.assessmentIsOpenChangeDict.hasOwnProperty(assessment.id) ?
                                                                                <div className='font-14'>
                                                                                    <Icon type='loading' className='mr-1' />
                                                                                    {this.state.assessmentIsOpenChangeDict[assessment.id] ?
                                                                                        <span>Testing is opening...</span> :
                                                                                        <span>Testing is closing...</span>}
                                                                                </div> :
                                                                                <Checkbox checked={testingIsOpen}
                                                                                    onChange={(e) => {
                                                                                        this.onAssessmentOpenChange(assessment, e.target.checked)
                                                                                    }}>
                                                                                    <span className='pl-1 font-14 font-black'>
                                                                                        {testingIsOpen ?
                                                                                            <span>Testing is open</span> :
                                                                                            <span>Testing is closed</span>}
                                                                                    </span>
                                                                                </Checkbox>}
                                                                        </Tooltip>

                                                                        {this.state.assessmentMode === 'current' ?
                                                                            <Tooltip title={<div className='font-14'>Delete Assessment</div>} mouseEnterDelay={.5}
                                                                                overlayStyle={{ minWidth: '159px' }}>
                                                                                <Button className='ml-auto' icon='delete' onClick={(e) => {
                                                                                    this.deleteAssessmentShowModal(assessment)
                                                                                }} />
                                                                            </Tooltip>
                                                                            :
                                                                            <Tooltip title={<div className='font-14'>Restore Assessment</div>} mouseEnterDelay={.5}
                                                                                overlayStyle={{ minWidth: '159px' }}>
                                                                                <Button className='ml-auto' icon='undo'
                                                                                    disabled={this.state.settingAssessmentStatus}
                                                                                    onClick={(e) => {
                                                                                        this.setAssessmentStatus(assessment, 'current')
                                                                                    }} />
                                                                            </Tooltip>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {/*
                                                                    <div className='ml-1'>
                                                                        <div><Icon type='arrow-up' className='font-14' /></div>
                                                                        <div><Icon type='arrow-down' className='font-14' /></div>
                                                                    </div>
                                                                    */}
                                                            </div>

                                                        </th>
                                                    })}
                                                    <th style={{
                                                        position: 'sticky', right: '0px',
                                                        zIndex: '1', background: '#e4e4e4',
                                                        verticalAlign: 'bottom',
                                                        minWidth: '188px',
                                                        width: '188px',
                                                    }}>
                                                        Average Grade
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/*
                                                <tr key={'section-tr-' + section.id}>
                                                    <td>{section.name}</td>
                                                    </tr>*/}
                                                {this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.students &&
                                                    this.props.teacherBaseProps.students.map((student, index) => {
                                                        if (this.props.sectionsFilterValue !== 'all' &&
                                                            this.props.teacherBaseProps.sectionsDict &&
                                                            this.props.teacherBaseProps.sectionsDict.hasOwnProperty(this.state.sectionsFilterValue)) {
                                                            const section = this.props.teacherBaseProps.sectionsDict[this.state.sectionsFilterValue]
                                                            if (section.hasOwnProperty('studentIdsDict') &&
                                                                section.studentIdsDict &&
                                                                !section.studentIdsDict.hasOwnProperty(student.id)) {
                                                                return false
                                                            }
                                                        }
                                                        let studentTotalGrade = 0
                                                        let studentNumAssessments = 0
                                                        return <tr className='font-16' key={'tr-' + student.id}>
                                                            <td className='ant-shadow-light background-light-grey' style={{ position: 'sticky', left: '0px', zIndex: '1' }}>
                                                                <div style={{ paddingLeft: '20px' }}>
                                                                    <PersonAvatar
                                                                        person={student}
                                                                        size={'large'}
                                                                        containerClassName="font-16"
                                                                        avatarClassName="font-16 font-bold mr-2"
                                                                        personClassName="font-black font-16 ellipsis"
                                                                        personStyle={{ maxWidth: '250px' }}
                                                                    />
                                                                </div>
                                                            </td>
                                                            {this.props.assessmentBaseProps && !this.props.assessmentBaseProps.dataRetrieved ?
                                                                <td>
                                                                    <div className='font-16'>
                                                                        {/* loading assessment placeholder element */}
                                                                    </div>
                                                                </td>
                                                                : ''}
                                                            {this.getAsessmentsForMode().map((assessment, index) => {
                                                                if (!assessment.hasOwnProperty('studentGrades')) {
                                                                    return false
                                                                }
                                                                if (this.state.sectionsFilterValue !== 'all' &&
                                                                    assessment.hasOwnProperty('sectionIds') &&
                                                                    assessment.sectionIds &&
                                                                    !assessment.sectionIds.includes(this.state.sectionsFilterValue)) {
                                                                    return false
                                                                }
                                                                if (!assessment.studentGrades.hasOwnProperty(student.id)) {
                                                                    return <td key={'td-' + assessment.id}>-</td>
                                                                }

                                                                let studentGrade = assessment.studentGrades[student.id]
                                                                if (assessment.hasOwnProperty('manualGrades') &&
                                                                    assessment.manualGrades &&
                                                                    assessment.manualGrades.hasOwnProperty(student.id)) {
                                                                    let manualGrade = assessment.manualGrades[student.id]
                                                                    // no automatic grade, use manual grade as it exists
                                                                    // manual grade has same structure as studentGrade
                                                                    if (!studentGrade.graded && manualGrade.graded) {
                                                                        studentGrade = manualGrade
                                                                    }
                                                                }
                                                                if (studentGrade.graded) {
                                                                    studentTotalGrade += studentGrade.finalGrade.grade
                                                                    studentNumAssessments += 1
                                                                }
                                                                return <td key={'td-' + assessment.id}>
                                                                    {studentGrade.graded ?
                                                                        (studentGrade.finalGrade.grade * 100).toFixed(2) + '%' :
                                                                        '-'}
                                                                </td>
                                                            })}
                                                            <td className='background-light-grey' style={{
                                                                position: 'sticky', right: '0px', zIndex: '1',
                                                                minWidth: '188px',
                                                                width: '188px',
                                                            }}>{studentNumAssessments > 0 ?
                                                                ((studentTotalGrade / studentNumAssessments) * 100).toFixed(2) + '%' :
                                                                '-'}</td>
                                                        </tr>
                                                    })}
                                            </tbody>
                                        </table>
                                    </div> : ''}
                                {/*this.getAsessmentsForMode().map((assessment, index) => {
                                        return <Link to={"/teacher/assessments/assessment/" + this.props.teacherBaseProps.teacher.id + "?assessment=" + assessment.id} key={assessment.id}>
                                            <div className={"p-3 br-20 font-black font-20 background-fff mb-3 ant-shadow ant-shadow-more-hover parent-hover relative flex flex-v-center"}>
                                                <div>Assessment</div>
                                                <div className='ml-auto'>{(assessment.timeStamp && assessment.timeStamp.hasOwnProperty('seconds') && assessment.timeStamp.seconds) ?
                                                    moment.utc(assessment.timeStamp.seconds * 1000).format("MM/DD/YYYY") :
                                                    ''}</div>
                                            </div>
                                        </Link>
                                    })*/}
                                <Modal
                                    title="Delete Assessment"
                                    visible={this.state.deleteModalVisible}
                                    onOk={this.hideDeleteModal}
                                    onCancel={this.hideDeleteModal}
                                    footer={[
                                        <Button key="back" size='large'
                                            onClick={this.hideDeleteModal}
                                            disabled={this.state.settingAssessmentStatus}>Cancel</Button>,
                                        <Button key="submit" size='large' type="danger" onClick={(e) => {
                                            this.setAssessmentStatus(this.state.selectedDeleteAssessment, 'deleted')
                                        }}
                                            disabled={this.state.settingAssessmentStatus || this.state.deleteInputValue !== 'delete'}>
                                            {this.state.settingAssessmentStatus ? <div><Icon type='loading' className='mr-1' />Deleting</div> : 'Delete'}
                                        </Button>,
                                    ]}
                                >
                                    <div className='font-20'>Assessment name:</div>
                                    <div className='font-20 mb-2'>{this.state.selectedDeleteAssessment ?
                                        getFullAssessmentDisplayName(this.state.selectedDeleteAssessment) : ''}</div>
                                    <div className='font-20'>Are you sure you want to delete this assessment? This will also delete the assessment for your students.</div>
                                    <div className='font-20 mt-2'>Note: You can restore the assessment after deleting by clicking on "Show deleted assessments"</div>
                                    <div className='font-20 mt-2 mb-05'>Please type the word "delete" to confirm.</div>
                                    <Input className='font-16' size='large' value={this.state.deleteInputValue} onChange={this.onDeleteInputValueChange} />
                                </Modal>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <CustomFooter />
            </div >
        )
    }
}

export default PacingGuideAssessments
